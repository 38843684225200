import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const [recipient, setRecipient] = useState('Lex');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [simulationSummary, setSimulationSummary] = useState({
    locations: {},
    agentLocations: {},
    simulationTime: 0,
    characterDescriptions: {},
    lastShift: "None",
    worldDescription: ""
  });
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);

  const fetchSimulationSummary = async () => {
    setIsLoadingSummary(true);
    try {
      const res = await axios.get(`${apiRoot}/api/simulation_summary`);
      setSimulationSummary(res.data.response);
    } catch (error) {
      console.error('Error fetching simulation summary:', error);
    } finally {
      setIsLoadingSummary(false);
    }
  };

  useEffect(() => {
    fetchSimulationSummary();
  }, []);

  const sendMessage = async () => {
    setIsSending(true);
    const newMessages = [...messages, { text: message, sender: 'Grace' }];
    setMessages(newMessages)
    setMessage('');
    try {
      const res = await axios.post(`${apiRoot}/api/chat`, {
        chat_history: newMessages,
        recipient
      });
      setMessages([...newMessages, { text: res.data.response, sender: recipient }]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const sendConcludedChat = async (chatHistory, recipient) => {
    try {
      setIsLoading(true);
      await axios.post(`${apiRoot}/api/conclude`, {
        chat_history: chatHistory,
        recipient
      });
      setMessages([]);
      fetchSimulationSummary();
      setIsLoading(false);
    } catch (error) {
      console.error('Error concluding chat:', error);
      setIsLoading(false);
    }
  };

  const concludeDialogue = () => {
    if (messages.length < 2) {
      return;
    }

    if (window.confirm('Are you sure you want to conclude this conversation?')) {
      sendConcludedChat(messages, recipient);
    }
  };

  const handleRecipientChange = (newRecipient) => {
    if (messages.length < 2) {
      setRecipient(newRecipient);
    }
    else if (window.confirm('Are you sure you want to finish this conversation?')) {
      sendConcludedChat(messages, recipient); // Call the sendConcludedChat function when finishing the conversation
      setRecipient(newRecipient);
      setMessages([]);
    }
  };


  return (

    <div className="App">
      {isLoading && (
        <div className="overlay">
          <div className="overlay-content">
            Please wait...
            <span className="loading-dots">.</span>
            <span className="loading-dots">.</span>
            <span className="loading-dots">.</span>
          </div>
        </div>
      )}
      <div className="container">
        <div className="chat">
          <h1>Undoing Gracia</h1>
          <select value={recipient} onChange={e => handleRecipientChange(e.target.value)}>
            <option>Lex</option>
            <option>Luna</option>
            <option>Tortugi</option>
          </select>
          <input
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && !isSending && sendMessage()}
            disabled={isSending}
          />
          <div className="button-controls">
            <button className="conclude" onClick={concludeDialogue} disabled={messages.length < 2}>Conclude the dialogue</button>
            <button className="send" onClick={sendMessage} disabled={isSending}>Send</button>
          </div>
          <div className="chat-history">
            {messages.map((msg, index) => (
              <p key={index}><b>{msg.sender}:</b> {msg.text}</p>
            ))}
            {isSending ? (
              <p><b>{recipient}:</b><span className="fake-answer loading-dots">..</span></p>
            ) : (
              null
            ) }
          </div>
        </div>
        <div className="info-box">
          <h2>Simulation Summary</h2>
          {isLoadingSummary ? (
            <p>Loading simulation summary<span className="loading-dots">..</span></p>
          ) : (
            <>
            <h3>Simulaton Time</h3>
            <p>{simulationSummary.simulationTime}</p>
            <h3>Environment</h3>
            <p>{simulationSummary.worldDescription}</p>
            <h3>Last narrative shift</h3>
            <p>{simulationSummary.lastShift}</p>
            <h3>Locations</h3>
            <ul>
              {Object.entries(simulationSummary.locations).map(([location, description], index) => (
                <li key={index}><b>{location}:</b> {description}</li>
              ))}
            </ul>
            <h3>Agent Locations</h3>
            <ul>
              {Object.entries(simulationSummary.agentLocations).map(([agent, location], index) => (
                <li key={index}><b>{agent}:</b> {location}</li>
              ))}
            </ul>
            <h3>Character Descriptions</h3>
            <ul>
              {Object.entries(simulationSummary.characterDescriptions).map(([character, description], index) => (
                <li key={index}><b>{character}:</b> {description}</li>
              ))}
            </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
